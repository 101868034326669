<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <span>
           ข้อมูลการขนส่ง
        </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <DateFilters :default-status="1" @onSendDate='addPayload'/>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getProducts'
                        label='ค้นหา'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <v-select filled label='สถานะ'
                    :items='options'
                    dense
                    item-value="value"
                    item-text="text"
                    hide-details
                    v-model='status'
                    @change='getProducts'
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table

          :headers='headers'
          :items='dataList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.id`]='{ index}'>
          {{ index + 1 }}
        </template>
        <template #[`item.tracking`]='{ item }'>
          <v-chip
              color="accent"
              :class="`v-chip-${$vuetify.theme.dark?'dark':'light'}-bg accent--text`"
              small
          >
            {{ item.tracking.length }} รายการ
          </v-chip>
        </template>
        <template #[`item.confirm_create`]='{ item }'>
          {{ item.confirm_create | sumdatetime }}
        </template>
        <template #[`item.order_total`]='{ item }'>
          {{ item.order_total | formatPrice }}
          <CurrentCurrency/>
        </template>
        <template #[`item.order_transportation_status`]='{ item }'>
          <StatusOrderTransport :status="String(item.order_transportation_status)"/>
        </template>

        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  v-on='on'
                  @click="updateStatusLoading = false;isShowTrackingList = true ;trackingList = item.tracking"
              >
                <v-icon>{{ mdiTextBoxCheckOutline }}</v-icon>
              </v-btn>
            </template>
            <span>ตรวจสอบสถานะ</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="isShowTrackingList" persistent max-width="500">
      <v-card>
        <v-card-title>
          รายการ Tracking
        </v-card-title>
        <v-divider></v-divider>
        <v-list two-line>
          <v-list-item
              v-for="(tracking,index) in trackingList"
              :key="index"

          >
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center">
                <v-icon
                    class="me-2"
                    :color="tracking.tracking_status ===0?'warning':tracking.tracking_status ===1?'success':'error'">
                  {{
                    tracking.tracking_status === 0 ? mdiTruckDeliveryOutline : tracking.tracking_status === 1 ? mdiTruckCheckOutline : mdiAlertRemoveOutline
                  }}
                </v-icon>
                {{ tracking.tracking_number }}
                <StatusOrderTracking :status="String(tracking.tracking_status)"/>
                <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                        color='primary'
                        icon
                        :href="`https://www-lotteglogis-com.translate.goog/home/reservation/tracking/linkView?InvNo=${tracking.tracking_number}&_x_tr_sl=auto&_x_tr_tl=th&_x_tr_hl=en-US`"
                        v-bind='attrs'
                        target="_blank"
                        v-on='on'
                    >
                      <v-icon>{{ mdiTextBoxCheckOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>ตรวจสอบสถานะ</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                        color='warning'
                        icon
                        :disabled="tracking.tracking_status ===0"
                        @click="updateStatusTracking(index,tracking,'0')"
                        v-bind='attrs'
                        v-on='on'
                    >
                      <v-icon>{{ mdiTruckDeliveryOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>กำลังจัดส่ง</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                        color='success'
                        icon
                        :disabled="tracking.tracking_status ===1"
                        @click="updateStatusTracking(index,tracking,1)"
                        v-bind='attrs'
                        v-on='on'
                    >
                      <v-icon>{{ mdiTruckCheckOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>จัดส่งเรียบร้อย</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator='{ on, attrs }'>
                    <v-btn
                        color='error'
                        icon
                        @click="updateStatusTracking(index,tracking,2)"
                        :disabled="tracking.tracking_status ===2"
                        v-bind='attrs'
                        v-on='on'
                    >
                      <v-icon>{{ mdiAlertRemoveOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>สินค้าส่งกลับ</span>
                </v-tooltip>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" outlined @click="isShowTrackingList = false;getProducts()">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FilterStatus from '@/components/FilterStatus'
import StatusBlock from '@/components/StatusBlock'
import StatusOrderTracking from "@/components/StatusOrderTracking";
import CurrentCurrency from "@/components/CurrentCurrency";
import {
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiCheck,
  mdiTextBoxCheck,
  mdiTruckCheckOutline,
  mdiTruckDeliveryOutline,
  mdiTextBoxCheckOutline,
  mdiAlertRemoveOutline,

} from '@mdi/js'
import useTransportList from "./useTransportList";
import { sumdatetime, formatPrice } from "@/services/filters";
import StatusOrderTransport from "@/components/StatusOrderTransport"
import DateFilters from "@/components/DateFilters";

export default {
  props: {},
  components: {
    FilterStatus,
    CurrentCurrency,
    StatusOrderTransport,
    StatusBlock,
    DateFilters,
    StatusOrderTracking,
  },
  filters: {
    sumdatetime,
    formatPrice
  },
  setup(_, { root, emit }) {
    return {
      ...useTransportList(),
      mdiCheck,
      mdiTextBoxCheck,
      mdiPencilOutline,
      mdiTruckCheckOutline,
      mdiTextBoxCheckOutline,
      mdiTruckDeliveryOutline,
      mdiAlertRemoveOutline,
      mdiDeleteOutline,
    }
  },

}
</script>

<style scoped>

</style>
