var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"px-2"},[_c('span',[_vm._v(" ข้อมูลการขนส่ง ")]),_c('v-spacer')],1),_c('DateFilters',{attrs:{"default-status":1},on:{"onSendDate":_vm.addPayload}}),_c('v-row',{staticClass:"px-2 pb-2"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"filled":"","label":"ค้นหา","dense":"","hide-details":"","placeholder":"คำค้นหา"},on:{"input":_vm.getProducts},model:{value:(_vm.textSearch),callback:function ($$v) {_vm.textSearch=$$v},expression:"textSearch"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-select',{attrs:{"filled":"","label":"สถานะ","items":_vm.options,"dense":"","item-value":"value","item-text":"text","hide-details":""},on:{"change":_vm.getProducts},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"no-data-text":"ไม่มีข้อมูล !","loading-text":"กำลังโหลดข้อมูล...","footer-props":_vm.footer,"no-results-text":"ไม่มีข้อมูล !"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.tracking",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:("v-chip-" + (_vm.$vuetify.theme.dark?'dark':'light') + "-bg accent--text"),attrs:{"color":"accent","small":""}},[_vm._v(" "+_vm._s(item.tracking.length)+" รายการ ")])]}},{key:"item.confirm_create",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("sumdatetime")(item.confirm_create))+" ")]}},{key:"item.order_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.order_total))+" "),_c('CurrentCurrency')]}},{key:"item.order_transportation_status",fn:function(ref){
var item = ref.item;
return [_c('StatusOrderTransport',{attrs:{"status":String(item.order_transportation_status)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.updateStatusLoading = false;_vm.isShowTrackingList = true ;_vm.trackingList = item.tracking}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTextBoxCheckOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("ตรวจสอบสถานะ")])])]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.isShowTrackingList),callback:function ($$v) {_vm.isShowTrackingList=$$v},expression:"isShowTrackingList"}},[_c('v-card',[_c('v-card-title',[_vm._v(" รายการ Tracking ")]),_c('v-divider'),_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.trackingList),function(tracking,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"me-2",attrs:{"color":tracking.tracking_status ===0?'warning':tracking.tracking_status ===1?'success':'error'}},[_vm._v(" "+_vm._s(tracking.tracking_status === 0 ? _vm.mdiTruckDeliveryOutline : tracking.tracking_status === 1 ? _vm.mdiTruckCheckOutline : _vm.mdiAlertRemoveOutline)+" ")]),_vm._v(" "+_vm._s(tracking.tracking_number)+" "),_c('StatusOrderTracking',{attrs:{"status":String(tracking.tracking_status)}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","href":("https://www-lotteglogis-com.translate.goog/home/reservation/tracking/linkView?InvNo=" + (tracking.tracking_number) + "&_x_tr_sl=auto&_x_tr_tl=th&_x_tr_hl=en-US"),"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTextBoxCheckOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("ตรวจสอบสถานะ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"warning","icon":"","disabled":tracking.tracking_status ===0},on:{"click":function($event){return _vm.updateStatusTracking(index,tracking,'0')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTruckDeliveryOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("กำลังจัดส่ง")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","icon":"","disabled":tracking.tracking_status ===1},on:{"click":function($event){return _vm.updateStatusTracking(index,tracking,1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiTruckCheckOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("จัดส่งเรียบร้อย")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","icon":"","disabled":tracking.tracking_status ===2},on:{"click":function($event){return _vm.updateStatusTracking(index,tracking,2)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiAlertRemoveOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("สินค้าส่งกลับ")])])],1)],1)],1)}),1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.isShowTrackingList = false;_vm.getProducts()}}},[_vm._v(" ปิด ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }