import { ref, onMounted } from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables.json'
import { api } from '@/services/api'
import store from "@/store";
import login from "@/views/Login";
import moment from "moment/moment";

export default function useTransportList() {
    const textSearch = ref('')
    const headers = ref([
        {
            text: '#',
            value: 'id',
            sortable: false,
            width: '50',
        },
        {
            text: 'เลขออเดอร์',
            value: 'order_code',
            width: '140',
        },
        {
            text: 'ลูกค้า',
            value: 'order_transportation_name',
            width: '200',
        },
        {
            text: 'ผู้ทำรายการ',
            value: 'user_create',
            width: '150',
        },
        {
            text: 'วันที่ยืนยันออเดอร์',
            sortable: false,
            value: 'confirm_create',
            width: '160',
        },
        {
            text: 'จำนวนเงิน',
            value: 'order_total',
            width: '120',
            align: 'end',
        },
        {
            text: 'จำนวน Tacking',
            value: 'tracking',
            width: '120',
            sortable: false,
            align: 'center',
        },
        {
            text: 'สถานะ',
            value: 'order_transportation_status',
            width: '100',
            sortable: false,
            align: 'center',
        },
        {
            text: 'ตัวเลือก',
            value: 'actions',
            width: '120',
            align: 'center fixed',
        },
    ])
    const itemsPerPage = ref(10)
    const dataList = ref([])
    const loading = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const status = ref('1')
    const options = ref(
        [
            {
                text: 'กำลังจัดส่ง',
                value: '1',
            },
            {
                text: 'จัดส่งเสร็จแล้ว',
                value: '2',
            }
        ])
    const selection = ref('')

    const selectedRows = ref([])

    const isShowTrackingList = ref(false)

    const trackingList = ref([])

    const isShowDetail = ref(false)

    const updateStatusLoading = ref(false)

    const moment = require('moment')

    const start = ref(moment(new Date()).format('YYYY-MM-DD'))

    const end = ref(moment(new Date()).format('YYYY-MM-DD'))

    const getProducts = () => {
        loading.value = true
        api.get({
            path: '/manager/tracking',
            param: `?search=${ textSearch.value }&status=${ status.value }&start=${ start.value }&end=${ end.value }&currency_id=${ localStorage.getItem('currencyId') }`,
        }).then(({ data }) => {
            dataList.value = data
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    onMounted(() => {
        getProducts()
    })

    const sendPrint = id => {
        if (id) {
            idList.value = [id]
        } else {
            idList.value = selectedRows.value.map(item => item.order_id)
        }
        isPrint.value = true
    }

    const updateStatusTracking = (index, item, status) => {
        updateStatusLoading.value = true
        api.put({
            path: '/tackingStatus',
            param: `${ item.tracking_id }`,
            body: {
                status: status,
                order_id: item.order_id
            }
        }).then(res => {
            store.commit('app/ALERT', {
                message: res.message,
                color: res.response ? 'success' : 'error',
            })
            if (res.response) {
                if (status === 1) {
                    trackingList.value[index].tracking_status = 1
                } else if (status === 2) {
                    trackingList.value[index].tracking_status = 2
                } else {
                    trackingList.value[index].tracking_status = 0
                }
            }
            updateStatusLoading.value = false
        }).catch(error => {
            updateStatusLoading.value = false
            console.log('error :', error)
        })


    }
    const addPayload = (dateStart, dateEnd) => {
        start.value = dateStart
        end.value = dateEnd
        getProducts()
    }
    return {
        textSearch,
        headers,
        isShowTrackingList,
        trackingList,
        updateStatusLoading,
        itemsPerPage,
        isAdd,
        updateStatusTracking,
        isShowDetail,
        isEdit,
        selection,
        selectedRows,
        options,
        addPayload,
        dataEdit,
        footer,
        isUpdateStatus,
        status,
        dataList,
        loading,
        getProducts,
    }
}
